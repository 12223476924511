<template>
	<div class="skills">
		<button class="btn btn-success save-skills" @click="updateData">Save instance</button>
		<div class="container">
			<div class="skills-excerpt" @click="updateExcerptEditStatus">
				<div v-if="!editableExcerpt.edit" class="excerpt">
					{{ editableExcerpt.excerpt == '' ? 'Click to edit' : editableExcerpt.excerpt  }}
				</div>
				<textarea v-else
					ref="editExcerpt"
					name="edit-excerpt"
					id="edit-excerpt"
					cols="30"
					rows="10"
					v-model="editableExcerpt.excerpt"
					@blur="editableExcerpt.edit = false"></textarea>
			</div>
			<div class="skills-container">
				<div class="skill" v-for="(skill, index) in editableSkills" @click="updateEditStatus(skill)" :key="index">
					<i
						class="fas fa-trash-alt delete-skill"
						@click="deleteSkill(index)"></i>
					<div v-if="!skill.edit">
						{{ skill.skill }}
					</div>
					<input v-else
						ref="editSkill"
						type="text"
						name="skill"
						id="skill"
						@click="getNewLength(skill.skill)"
						:size="getNewLength(skill.skill)"
						@keyup.enter="skill.edit = false"
						@blur="skill.edit = false"
						v-model="skill.skill">
				</div>
				<div class="skill" @click="updateAddSkillInput">
					<i v-if="showAddIcon" class="fas fa-plus"></i>
					<input v-else
						ref="addSkill"
						type="text"
						name="add-new-skill"
						id="add-new-skill"
						:size="getNewLength(newSkill)"
						@keyup.enter="addEditNewSkill(newSkill, 'false')"
						@blur="showAddIcon = true"
						v-model="newSkill">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'Skills',
	data() {
		return {
			editableSkills: [],
			showAddIcon: true,
			newSkill: '',
			editableExcerpt: {
				excerpt: '',
				edit: false
			}
		}
	},
	methods: {
		...mapActions({
			saveSkills: 'updateSkills',
			saveExcerpt: 'updateSkillsExcerpt'
		}),
		updateSkills() {
			let skills = '';
			this.editableSkills.forEach(skill => {
				skills += ', ' + skill.skill;
			})
			this.saveSkills(skills.slice(2))
		},
		updateSkillsExcerpt() {
			this.saveExcerpt(this.editableExcerpt.excerpt);
		},
		updateData() {
			this.updateSkills();
			this.updateSkillsExcerpt();
			this.$emit('notification', 'Changes saved locally!');
		},
		loadData() {
			let skills = this.$store.state.skills.split(', ');
			skills.forEach(skill => {
				this.addEditNewSkill(skill);
			});
			this.editableExcerpt.excerpt = this.$store.state.skillsExcerpt
		},
		addEditNewSkill(skill, edit='true') {
			let temp = {
				skill: skill,
				edit: false
			};
			this.editableSkills.push(temp);
			if (edit == 'false') {
				this.newSkill = '';
				this.showAddIcon = false;
			}
		},
		getNewLength(skill) {
			if (skill.length-2 < 0) {
				return skill.length;
			}
			return skill.length - 2;
		},
		updateEditStatus(skill) {
			skill.edit = true;
			setTimeout(() => {
				this.$refs.editSkill.focus();
			}, 100);
		},
		updateAddSkillInput() {
			this.showAddIcon = false;
			setTimeout(() => {
				this.$refs.addSkill.focus();
			}, 100);
		},
		updateExcerptEditStatus() {
			this.editableExcerpt.edit = true;
			setTimeout(() => {
				this.$refs.editExcerpt.focus();
			}, 100);
		},
		deleteSkill(index) {
			this.editableSkills.splice(index, 1);
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style scoped>
.skills {
	padding: 5rem 0;
}

.save-skills {
	display: flex;
    margin: 0 10vw;
}

input[type=text], input[type=password], textarea {
	border-radius: 8px;
	padding: 0;
	vertical-align: middle;
	border: none;
	background: none !important;
	opacity: 0.9;
	width: 100%;
	color: white;
}
input[type=text]:focus, input[type=password]:focus, textarea:focus {
	background-color: #ddd;
	outline: none;
}

.container {
	display: grid;
	justify-content: center;
	align-items: center;
	color: white;
	grid-auto-flow: column;
	grid-template-columns: 50%;
	margin-top: 3rem;
	padding: 0;
}

.excerpt {
	cursor: pointer;
}
.skills-container {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1.2rem;
}
.skill {
	padding: 1.1rem;
	border: 0.5rem double darkcyan;
	border-radius: 1rem;
	border-style: outset;
	position: relative;
	cursor: pointer;
	transition: all 150ms ease-in-out;
}
.skill:hover {
	background-color: cadetblue; 
}
.skills-excerpt {
	text-align: left;
	margin-right: 1.5rem;
	white-space: break-spaces;
	font-size: 1.1rem;
}

.fa-trash-alt {
	margin: auto 0;
	color: burlywood;
	background-color: #a90909;
	padding: 0.6rem;
	border-radius: 50%;
	filter: sepia(1);
	transition: all 200ms ease-in-out;
	cursor: pointer;
	position: absolute;
	top: -19px;
	right: -18px
}
.fa-trash-alt:hover {
	filter: sepia(0)
}

@media screen and (max-width: 790px) {
	.container {
		grid-auto-flow: row;
		grid-template-columns: 100%;
	}
	.excerpt, textarea {
		margin: 0 20px 2rem 20px;
	}
	.skills-container {
		justify-content: center;
	}
}
</style>